@font-face {
  font-family: "Mint Grotesk";
  src: url("../assets/fonts/MintGrotesk/MintGroteskTrial-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mint Grotesk";
  src: url("../assets/fonts/MintGrotesk/MintGroteskTrial-Bold.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mint Grotesk";
  src: url("../assets/fonts/MintGrotesk/MintGroteskTrial-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mint Grotesk";
  src: url("../assets/fonts/MintGrotesk/MintGroteskItalicTrial-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Mint Grotesk";
  src: url("../assets/fonts/MintGrotesk/MintGroteskItalicTrial-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "GT Flexa";
  src: url("../assets/fonts/GT-Flexa/GT-Flexa-Medium-Trial.woff2")
    format("woff2");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "GT Flexa";
  src: url("../assets/fonts/GT-Flexa/GT-Flexa-Medium-Italic-Trial.woff2")
    format("woff2");
  font-weight: medium;
  font-style: italic;
}

@font-face {
  font-family: "GT Flexa";
  src: url("../assets/fonts/GT-Flexa/GT-Flexa-Bold-Trial.woff2")
    format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT Flexa";
  src: url("../assets/fonts/GT-Flexa/GT-Flexa-Bold-Italic-Trial.woff2")
    format("woff2");
  font-weight: bold;
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GT Flexa";
  font-weight: 500;
}
